import styled from "styled-components";

export const HeaderStyled = styled.header`
  margin-right: 0.625rem;
  background-color: transparent;
  margin-top: 1.3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  position: absolute;
  width: 100%;
  z-index: 3;
  h1 {
    color: #a1a6b4;
    font-size: 3.125rem;
  }
  p {
    padding: 0;
    font-size: 2.188rem;
    color: #a1a6b4;
  }

  @media screen and (max-width: 960px) {
  }

  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 900px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    h1 {
      font-size: 1.875rem;
    }
    p {
      font-size: 0.938rem;
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  h1 {
    font-size: 3.313rem;
    font-weight: 200;
  }

  @media screen and (max-width: 900px) {
    align-items: flex-start;
    h1 {
      font-size: 1.875rem;
    }
    p {
      font-size: 0.5rem;
    }
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 1.5rem;
  /* font-weight: 600; */

  h1 {
    font-weight: 200;
  }
  p {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 900px) {
    h1 {
      font-size: 1.875rem;
    }
    p {
      font-size: 0.938rem;
    }
  }
`;

export const NavWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 1.5rem;
  margin-left: 1.5rem;

  @media screen and (max-width: 900px) {
  }
`;

export const ProjectWrapper = styled.div`
  position: relative;
  top: -0.625rem;
  color: #a1a6b4;

  @media screen and (max-width: 900px) {
    h5 {
      font-size: 0.6rem;
      margin-top: 10px;
    }
    h6 {
      font-size: 0.6rem;
    }
  }
`;
