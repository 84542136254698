import React from "react";
import BlackBg from "../../../BlackBg/BlackBg";
import Header from "../../../Headers/Header";
import SimpleImageSlider from "react-simple-image-slider";
import IMG1 from "Assets/Film/sandeep/Sandeep1.jpg";
import IMG2 from "Assets/Film/sandeep/Sandeep2.jpg";
import IMG3 from "Assets/Film/sandeep/Sandeep3.jpg";
import IMG4 from "Assets/Film/sandeep/Sandeep4.jpg";
import IMG5 from "Assets/Film/sandeep/Sandeep5.jpg";
import IMG6 from "Assets/Film/sandeep/Sandeep6.jpg";
import IMG7 from "Assets/Film/sandeep/Sandeep7.jpg";
import IMG8 from "Assets/Film/sandeep/Sandeep8.jpg";
import IMG9 from "Assets/Film/sandeep/Sandeep9.jpg";
import IMG10 from "Assets/Film/sandeep/Sandeep10.jpg";
import IMG11 from "Assets/Film/sandeep/Sandeep11.jpg";
import IMG12 from "Assets/Film/sandeep/Sandeep12.jpg";
import IMG13 from "Assets/Film/sandeep/Sandeep13.jpg";
import IMG14 from "Assets/Film/sandeep/Sandeep14.jpg";
import IMG15 from "Assets/Film/sandeep/Sandeep15.jpg";
import IMG16 from "Assets/Film/sandeep/Sandeep16.jpg";
import IMG17 from "Assets/Film/sandeep/Sandeep17.jpg";
import IMG18 from "Assets/Film/sandeep/Sandeep18.jpg";
import IMG19 from "Assets/Film/sandeep/Sandeep19.jpg";
import IMG20 from "Assets/Film/sandeep/Sandeep20.jpg";

import Iframe from "react-iframe";
import Footer from "../../../Footer";

const Sandeep = () => {
  const style: React.CSSProperties = {
    backgroundPosition: "center center",
  };
  const styleF: React.CSSProperties = {
    height: "100vh",
    width: "100vw",
    zIndex: "2",
    backgroundColor: "black",
    /*  paddingBottom: "20px", */
  };
  return (
    <BlackBg>
      <Header type="film" name="sandeep!" dir="Paolo Gentilella" redir="film" />
      <SimpleImageSlider
        width={"100vw"}
        height={"100vh"}
        images={[
          IMG1,
          IMG2,
          IMG3,
          IMG4,
          IMG5,
          IMG6,
          IMG7,
          IMG8,
          IMG9,
          IMG10,
          IMG11,
          IMG12,
          IMG13,
          IMG14,
          IMG15,
          IMG16,
          IMG17,
          IMG18,
          IMG19,
          IMG20,
        ]}
        style={style}
        showNavs={true}
        showBullets={false}
        slideDuration={1}
        autoPlayDelay={5}
        autoPlay={true}
      />
      {/*     <Iframe
        url="https://player.vimeo.com/video/937709310?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        width="100%"
        display="block"
        styles={styleF}
        position="relative"
        frameBorder={0}
        allowFullScreen={false}
      />*/}
      <Footer />
    </BlackBg>
  );
};

export default Sandeep;
