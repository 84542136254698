import React from "react";
import BlackBg from "../../../BlackBg/BlackBg";
import Header from "../../../Headers/Header";
import SimpleImageSlider from "react-simple-image-slider";
import IMG1 from "../../../../Assets/Commercial/Jan/Jan1.jpg";
import IMG2 from "../../../../Assets/Commercial/Jan/Jan2.jpg";
import IMG3 from "../../../../Assets/Commercial/Jan/Jan3.jpg";
import IMG4 from "../../../../Assets/Commercial/Jan/Jan4.jpg";
import IMG5 from "../../../../Assets/Commercial/Jan/Jan5.jpg";
import IMG6 from "../../../../Assets/Commercial/Jan/Jan6.jpg";
import IMG7 from "../../../../Assets/Commercial/Jan/Jan7.jpg";
import IMG8 from "../../../../Assets/Commercial/Jan/Jan8.jpg";
import IMG9 from "../../../../Assets/Commercial/Jan/Jan9.jpg";
import IMG10 from "../../../../Assets/Commercial/Jan/Jan10.jpg";
import IMG11 from "../../../../Assets/Commercial/Jan/Jan11.jpg";
import IMG12 from "../../../../Assets/Commercial/Jan/Jan12.jpg";
import IMG13 from "../../../../Assets/Commercial/Jan/Jan13.jpg";
import IMG14 from "../../../../Assets/Commercial/Jan/Jan14.jpg";
import IMG15 from "../../../../Assets/Commercial/Jan/Jan15.jpg";
import Iframe from "react-iframe";
import Footer from "../../../Footer";

const CzechTeam = () => {
  const style: React.CSSProperties = {
    backgroundPosition: "center center",
  };
  const styleF: React.CSSProperties = {
    height: "100vh",
    width: "100vw",
    zIndex: "2",
    backgroundColor: "black",
    /*  paddingBottom: "20px", */
  };
  return (
    <BlackBg>
      <Header
        type="commercial"
        name="“THIS IS CZECH TEAM UNIFORM FOR PARIS 2024”"
        dir="Tomáš Fiala"
        redir="commercial"
      />
      <SimpleImageSlider
        width={"100vw"}
        height={"100vh"}
        images={[
          IMG1,
          IMG2,
          IMG3,
          IMG4,
          IMG5,
          IMG6,
          IMG7,
          IMG8,
          IMG9,
          IMG10,
          IMG11,
          IMG12,
          IMG13,
          IMG14,
          IMG15,
        ]}
        style={style}
        showNavs={true}
        showBullets={false}
        slideDuration={1}
        autoPlayDelay={5}
        autoPlay={true}
      />
      <Iframe
        url="https://player.vimeo.com/video/980668958?h=549d957f8d"
        width="100%"
        display="block"
        styles={styleF}
        position="relative"
        frameBorder={0}
        allowFullScreen={false}
      />
      <Footer />
    </BlackBg>
  );
};

export default CzechTeam;
