import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import Home from "./Components/Pages/Home";
import Film from "./Components/Pages/Film";
import Comercial from "./Components/Pages/Commercial";

import MusicVideo from "./Components/Pages/MusicVideo";

import Amori from "Components/Pages/ProjectPages/Film/Amori";
import LetCharity from "Components/Pages/ProjectPages/Commercial/100LetCharity";
import DaranekDetem from "Components/Pages/ProjectPages/Commercial/DaranekDetem";
import OzviSe from "Components/Pages/ProjectPages/Commercial/OzviSe";
import PlodyWork from "Components/Pages/ProjectPages/Commercial/PlodyWork";
import StudyIn from "Components/Pages/ProjectPages/Commercial/StudyIn";
import Toothbrush from "Components/Pages/ProjectPages/MusicVideo/Toothbrush";
import ScrollToTop from "Hooks/ScrollToTop";
import Born from "Components/Pages/ProjectPages/Film/Born";
import Photography from "Components/Pages/Photography";
import WhatYouWant from "./Components/Pages/ProjectPages/MusicVideo/WhatYouWant";
import Nice from "./Components/Pages/ProjectPages/Commercial/Nice";
import PojdPadlovat from "./Components/Pages/ProjectPages/Commercial/PojdPadlovat";
import Sandeep from "./Components/Pages/ProjectPages/Film/Sandeep";
import CzechTeam from "./Components/Pages/ProjectPages/Commercial/CzechTeam";
import StaleSom from "./Components/Pages/ProjectPages/MusicVideo/StaleSom";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  body {
  /*   font-family: "Bebas Neue",cursive; */

  font-family: 'Anybody', cursive;
  
    /* Add other global styles here */
  }
`;

const App = () => {
  return (
    <>
      <GlobalStyles />
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/film" element={<Film />} />
          <Route path="/commercial" element={<Comercial />} />
          <Route path="/music-video" element={<MusicVideo />} />
          <Route path="/photography" element={<Photography />} />
          <Route path="/film/amori-dolori" element={<Amori />} />
          <Route path="/film/sandeep" element={<Sandeep />} />
          <Route
            path="/film/I-was-born-from-burning-apples"
            element={<Born />}
          />
          <Route path="/commercial/100-let-charity" element={<LetCharity />} />
          <Route path="/commercial/daranek-detem" element={<DaranekDetem />} />
          <Route path="/commercial/ozvi-se" element={<OzviSe />} />
          <Route path="/commercial/nice" element={<Nice />} />
          <Route path="/commercial/paris-24" element={<CzechTeam />} />
          <Route path="/commercial/pojd-padlovat" element={<PojdPadlovat />} />
          <Route path="/commercial/plody-work" element={<PlodyWork />} />
          <Route path="/commercial/study-in" element={<StudyIn />} />
          <Route path="/music-video/stale-som" element={<StaleSom />} />
          <Route path="/music-video/toothbrush" element={<Toothbrush />} />
          <Route path="/music-video/what-u-want" element={<WhatYouWant />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
