import React from "react";
import BlackBg from "../../../BlackBg/BlackBg";
import Header from "../../../Headers/Header";
import SimpleImageSlider from "react-simple-image-slider";
import IMG1 from "Assets/Commercial/Nice/Nice1.JPG";
import IMG2 from "Assets/Commercial/Nice/Nice2.JPG";
import IMG3 from "Assets/Commercial/Nice/Nice3.JPEG";
import IMG4 from "Assets/Commercial/Nice/Nice4.JPEG";
import IMG5 from "Assets/Commercial/Nice/Nice5.JPEG";
import IMG6 from "Assets/Commercial/Nice/Nice6.JPG";
import IMG7 from "Assets/Commercial/Nice/Nice7.JPG";
import IMG8 from "Assets/Commercial/Nice/Nice8.JPG";
import Iframe from "react-iframe";
import Footer from "../../../Footer";

const Nice = () => {
  const style: React.CSSProperties = {
    backgroundPosition: "center center",
  };
  const styleF: React.CSSProperties = {
    height: "100vh",
    width: "100vw",
    zIndex: "2",
    backgroundColor: "black",
    /*  paddingBottom: "20px", */
  };
  return (
    <BlackBg>
      <Header
        type="commercial"
        name="nice"
        dir="Paolo Gentilella"
        redir="commercial"
      />
      <SimpleImageSlider
        width={"100vw"}
        height={"100vh"}
        images={[IMG1, IMG6, IMG2, IMG3, IMG4, IMG5, IMG6, IMG7, IMG8]}
        style={style}
        showNavs={true}
        showBullets={false}
        slideDuration={1}
        autoPlayDelay={5}
        autoPlay={true}
      />
      <Iframe
        url="https://player.vimeo.com/video/938144885?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        width="100%"
        display="block"
        styles={styleF}
        position="relative"
        frameBorder={0}
        allowFullScreen={false}
      />
      <Footer />
    </BlackBg>
  );
};

export default Nice;
