import React from "react";

import Footer from "../Footer";

import { Container } from "../Styled/FilmContainer.styled";

import Header from "../Headers/Header";
import Project from "../Project/Project";
import "../../App.css";
import AmoriImg from "../../Assets/Film/Amori/Amori1.jpg";

import IMG1 from "../../Assets/Photography/Photography1.JPG";
import IMG2 from "../../Assets/Photography/Photography2.jpg";
import IMG3 from "../../Assets/Photography/Photography3.jpg";
import IMG4 from "../../Assets/Photography/Photography4.jpg";
import IMG5 from "../../Assets/Photography/Photography5.jpg";
import IMG6 from "../../Assets/Photography/Photography6.jpg";
import IMG7 from "../../Assets/Photography/Photography7.jpg";
import IMG8 from "../../Assets/Photography/Photography8.JPG";
import IMG9 from "../../Assets/Photography/Photography9.JPG";
import IMG10 from "../../Assets/Photography/Photography10.jpg";
import IMG11 from "../../Assets/Photography/Photography11.jpg";
import IMG12 from "../../Assets/Photography/Photography12.JPG";
import IMG13 from "../../Assets/Photography/Photography13.JPG";
import IMG14 from "../../Assets/Photography/Photography14.JPG";
import IMG15 from "../../Assets/Photography/Photography15.jpg";
import IMG16 from "../../Assets/Photography/Photography16.jpg";
import IMG17 from "../../Assets/Photography/Photography17.jpg";
import IMG18 from "../../Assets/Photography/Photography18.jpg";
import IMG19 from "../../Assets/Photography/Photography19.jpg";
import IMG20 from "../../Assets/Photography/Photography20.jpg";
import Photo from "Components/Project/Photo";
import BlackBg from "Components/BlackBg/BlackBg";

function Photography() {
  const photos = [
    { img: IMG1 },
    { img: IMG2 },
    { img: IMG3 },
    { img: IMG4 },
    { img: IMG5 },
    { img: IMG6 },
    { img: IMG7 },
    { img: IMG8 },
    { img: IMG9 },
    { img: IMG10 },
    { img: IMG11 },
    { img: IMG12 },
    { img: IMG13 },
    { img: IMG14 },
    { img: IMG15 },
    { img: IMG16 },
    { img: IMG17 },
    { img: IMG18 },
    { img: IMG19 },
    { img: IMG20 },
  ];
  return (
    <BlackBg>
      <Header type="photography" redir="" />
      {photos.map((i) => {
        return (
          <Photo dir={""} name={""} LinkTo={""} position={""} IMG={i.img} />
        );
      })}
      <Footer />
    </BlackBg>
  );
}

export default Photography;
