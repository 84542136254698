import Footer from "Components/Footer";
import Header from "Components/Headers/Header";
import React from "react";
import SimpleImageSlider from "react-simple-image-slider";

import IMG1 from "../../../../Assets/Commercial/DaranekDetem/DaranekDetem4.jpg";
import IMG2 from "../../../../Assets/Commercial/DaranekDetem/DaranekDetem5.jpg";
import IMG3 from "../../../../Assets/Commercial/DaranekDetem/DaranekDetem2.jpg";
import IMG4 from "../../../../Assets/Commercial/DaranekDetem/DaranekDetem3.jpg";
import Iframe from "react-iframe";
import BlackBg from "Components/BlackBg/BlackBg";

const DaranekDetem = () => {
  const style: React.CSSProperties = {
    backgroundPosition: "center center",
  };
  const styleF: React.CSSProperties = {
    height: "100vh",
    width: "100vw",
    zIndex: "2",
    backgroundColor: "black",
    /*  paddingBottom: "20px", */
  };
  return (
    <BlackBg>
      <Header
        type="commercial"
        name="daránek dětem"
        dir="Valeria Recman"
        redir="commercial"
      />
      <SimpleImageSlider
        width={"100vw"}
        height={"74.352vh"}
        images={[IMG1, IMG2, IMG3, IMG4]}
        style={style}
        showNavs={true}
        showBullets={false}
        slideDuration={1}
        autoPlayDelay={5}
        autoPlay={true}
      />
      <Iframe
        url="https://player.vimeo.com/video/762889742?h=81617fd509"
        width="100%"
        display="block"
        styles={styleF}
        position="relative"
        frameBorder={0}
        allowFullScreen={false}
      />
      <Footer />
    </BlackBg>
  );
};

export default DaranekDetem;
