import React from "react";
import { StyledFooter } from "./Styled/Footer.styled";
import { FaVimeoV, FaInstagram } from "react-icons/fa";
import CopyRight from "./CopyRight";

const Footer = () => {
  return (
    <StyledFooter>
      <div>
        {" "}
        <a
          href="https://vimeo.com/ondrejandresic"
          rel="noreferrer"
          target="_blank"
        >
          <FaVimeoV />
        </a>
        <a
          href="https://www.instagram.com/ondrej.namespace/"
          rel="noreferrer"
          target="_blank"
        >
          <FaInstagram />
        </a>
      </div>
      <div>
        <CopyRight />
      </div>
    </StyledFooter>
  );
};

export default Footer;
