import styled from "styled-components";

export const CopyRightWrapper = styled.div`
  color: #a1a6b4;
  font-size: 0.813rem;

  @media screen and (max-width: 900px) {
    font-size: 0.6rem;
  }
`;
