import React from "react";
import { MenuContainer } from "./Styled/Portfolio.styled";
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <>
      <MenuContainer>
        <a href=".">
          <Link to="/film">film</Link>
        </a>
        <a href=".">
          <Link to="/commercial">commercial</Link>
        </a>
        <a href=".">
          <Link to="/music-video">music video</Link>
        </a>
        <a href=".">
          <Link to="/photography">photography</Link>
        </a>
      </MenuContainer>
    </>
  );
};

export default Menu;
