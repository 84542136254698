import React from "react";
import BlackBg from "../../../BlackBg/BlackBg";
import Header from "../../../Headers/Header";
import SimpleImageSlider from "react-simple-image-slider";
import IMG1 from "../../../../Assets/Commercial/Pojd/Pojd1.jpg";
import IMG2 from "../../../../Assets/Commercial/Pojd/Pojd2.jpg";
import IMG3 from "../../../../Assets/Commercial/Pojd/Pojd3.jpg";
import IMG4 from "../../../../Assets/Commercial/Pojd/Pojd4.jpg";
import IMG5 from "../../../../Assets/Commercial/Pojd/Pojd5.jpg";
import IMG6 from "../../../../Assets/Commercial/Pojd/Pojd6.jpg";

import Iframe from "react-iframe";
import Footer from "../../../Footer";

const PojdPadlovat = () => {
  const style: React.CSSProperties = {
    backgroundPosition: "center center",
  };
  const styleF: React.CSSProperties = {
    height: "100vh",
    width: "100vw",
    zIndex: "2",
    backgroundColor: "black",
    /*  paddingBottom: "20px", */
  };
  return (
    <BlackBg>
      <Header
        type="commercial"
        name="pojď pádlovat"
        dir="Jakub Veselý"
        redir="commercial"
      />
      <SimpleImageSlider
        width={"100vw"}
        height={"100vh"}
        images={[IMG1, IMG2, IMG3, IMG4, IMG5, IMG6]}
        style={style}
        showNavs={true}
        showBullets={false}
        slideDuration={1}
        autoPlayDelay={5}
        autoPlay={true}
      />
      <Iframe
        url="https://player.vimeo.com/video/917214224?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        width="100%"
        display="block"
        styles={styleF}
        position="relative"
        frameBorder={0}
        allowFullScreen={false}
      />
      <Footer />
    </BlackBg>
  );
};

export default PojdPadlovat;
