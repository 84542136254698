import React, { ReactNode } from "react";
import { BlackBgWrap } from "./BlackBg.styled";

interface AppLayoutProps {
  children: ReactNode; // Define children prop with ReactNode type
}

const BlackBg: React.FC<AppLayoutProps> = ({ children }) => {
  return <BlackBgWrap>{children}</BlackBgWrap>;
};

export default BlackBg;
