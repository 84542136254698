import styled from "styled-components";

export const Wrapper = styled.div`
  background-position: center center;

  /*   
  @media screen and (max-width: 960px) {
    width: 100vw;
    position: relative;
    height: 80vh;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    position: relative;
    height: 60vh;
  }
  @media screen and (max-width: 900px) {
    width: 100vw;
    position: relative;
    height: 40vh;
  } */
`;
