import styled from "styled-components";

export const ContactsContainer = styled.div`
  font-size: 3.375rem;
  row-gap: 1.25rem;
  margin-left: 1.25rem;
  h5 {
    color: #a1a6b4;
    display: table;
    text-decoration: none;
    cursor: context-menu;
    font-weight: 200;
  }

  h5:hover {
    color: #7a542e;
  }

  @media screen and (max-width: 960px) {
  }

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 900px) {
    font-size: 1rem;
  }
`;
