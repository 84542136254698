import React from "react";
import Header from "../Headers/Header";
import Footer from "../Footer";
import { Container } from "Components/Styled/FilmContainer.styled";
import Project from "Components/Project/Project";

import LetCharityImg from "../../Assets/Commercial/100LetCharity/100LetCharity6.jpg";
import DaranekDetemImg from "../../Assets/Commercial/DaranekDetem/DaranekDetem1.jpg";
import OzviSeImg from "../../Assets/Commercial/OzviSe/OzviSe13.jpg";
import PlodyWorkImg from "../../Assets/Commercial/PlodyWork/PlodyWork1.jpg";
import StudyInImg from "../../Assets/Commercial/StudyIn/StudyIn31.jpg";
import NiceImg from "../../Assets/Commercial/Nice/Nice0.JPG";
import PojdImg from "../../Assets/Commercial/Pojd/Pojd0.jpg";
import JanImg from "../../Assets/Commercial/Jan/Jan0.jpg";
import BlackBg from "Components/BlackBg/BlackBg";

const Commercial = () => {
  return (
    <BlackBg>
      <Header type="commercial" redir="" />
      <Container>
        <Project
          position="bottom"
          IMG={StudyInImg}
          dir={"Bartłomiej Klimsza"}
          name={"study in"}
          LinkTo={"study-in"}
        />
        <Project
          position="bottom"
          IMG={OzviSeImg}
          dir={"Valeria Recman"}
          name={"#ozvise"}
          LinkTo={"ozvi-se"}
        />
        <Project
          position="bottom"
          IMG={NiceImg}
          dir="Paolo Gentilella"
          name="nice"
          LinkTo={"nice"}
        />{" "}
        <Project
          position="bottom"
          IMG={JanImg}
          dir="Tomáš Fiala"
          name="“THIS IS CZECH TEAM UNIFORM FOR PARIS 2024”"
          LinkTo={"paris-24"}
        />
        <Project
          position="bottom"
          IMG={PojdImg}
          dir="Jakub Veselý"
          name="pojď pádlovat"
          LinkTo={"pojd-padlovat"}
        />
        <Project
          position="bottom"
          IMG={LetCharityImg}
          dir={"Valeria Recman"}
          name={"100 let charity"}
          LinkTo={"100-let-charity"}
        />
        <Project
          position="bottom"
          IMG={DaranekDetemImg}
          dir={"Valeria Recman"}
          name={"daránek dětem"}
          LinkTo={"daranek-detem"}
        />
        <Project
          position="bottom"
          IMG={PlodyWorkImg}
          dir={"Valeria Recman"}
          name={"plody.work"}
          LinkTo={"plody-work"}
        />
      </Container>
      <Footer />
    </BlackBg>
  );
};

export default Commercial;
