import styled from "styled-components";

export const MenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 3.313rem;
  margin-right: 5vw;
  font-weight: 200;
  a {
    color: #a1a6b4;

    display: table;
    text-decoration: none;
    cursor: context-menu;
  }

  a:hover {
    color: #7a542e;
  }

  @media screen and (max-width: 960px) {
    /*    body {
      background-size: 200%;
    } */
  }

  @media screen and (max-width: 768px) {
    /*   display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: none; */
    font-size: 2.5rem;
  }

  @media screen and (max-width: 900px) {
    font-size: 1rem;
    margin-right: 3vw;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 60vh;
  position: relative;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    padding-top: 60vh;
  }
`;
