import React from "react";
import Header from "../Headers/Header";
import Footer from "../Footer";

import ToothImg from "../../Assets/MusicVideo/Toothbrush/Toothbrush4.jpg";
import WhatImg from "../../Assets/MusicVideo/WhatUWant/What0.jpg";
import StaleSomImg from "../../Assets/MusicVideo/StaleSom/StaleSom0.jpg";

import Project from "Components/Project/Project";
import BlackBg from "Components/BlackBg/BlackBg";

const MusicVideo = () => {
  return (
    <BlackBg>
      <Header type="music video" redir="" />

      <Project
        dir={"Tomáš Fiala"}
        name={"stále som (16mm)"}
        LinkTo={"stale-som"}
        position="bottom"
        IMG={StaleSomImg}
      />
      <Project
        dir={"Tomáš Fiala"}
        name={"what u want?"}
        LinkTo={"what-u-want"}
        position="bottom"
        IMG={WhatImg}
      />
      <Project
        dir={"Aleš Valtr"}
        name={"toothbrush"}
        LinkTo={"toothbrush"}
        position={"bottom"}
        IMG={ToothImg}
      />
      <Footer />
    </BlackBg>
  );
};

export default MusicVideo;
