import styled from "styled-components";

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  object-fit: cover;
`;

export const Container = styled.div`
  position: relative;
`;

export const Image = styled.img`
  width: 100vw;
  height: auto;

  vertical-align: middle;
  display: inline-block;

  /*   @media screen and (max-width: 960px) {
    width: 100vw;
    height: 100vh;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 100vh;
  }

  @media screen and (max-width: 900px) {
    width: 100vw;
    height: 100vh;
  } */
`;

export const Text = styled.div<{ position?: string }>`
  position: absolute;
  top: ${({ position }) => (position === "top" ? "0" : "auto")};
  bottom: ${({ position }) => (position === "bottom" ? "0" : "auto")};
  left: 0;
  width: 100%;
  margin-left: 1.25rem;
  margin-bottom: 11.063rem;
  height: auto;

  h1 {
    /*  font-family: "Bebas Neue", cursive; */
    font-family: "Anybody", cursive;
    color: #a1a6b4;
    font-size: 1.563rem;
    font-weight: 200;

    text-shadow: 3px 4px 1px rgba(0, 0, 0, 0.3);

    /*   background-color: black;
      width: 230px;*/
  }

  @media screen and (max-width: 900px) {
    position: absolute;
    top: ${({ position }) => (position === "top" ? "0" : "auto")};
    bottom: ${({ position }) => (position === "bottom" ? "0" : "auto")};
    left: 0;
    width: 100%;
    margin-left: 3vw;
    margin-bottom: 4vh;
    height: auto;
    h1 {
      /*  font-family: "Bebas Neue", cursive; */
      font-family: "Anybody", cursive;
      color: #a1a6b4;
      font-size: 1rem;
      font-weight: 200;
    }
  }
`;
