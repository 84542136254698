import React from "react";
import Header from "../Headers/Header";
import Contacts from "../Contacts";

import Footer from "../Footer";
import Menu from "../Menu";
import { MenuWrapper } from "Components/Styled/Portfolio.styled";
import SimpleImageSlider from "react-simple-image-slider";

import IMG1 from "../../Assets/HomePage/IMG1.jpg";
import IMG2 from "../../Assets/HomePage/IMG2.jpg";
import IMG3 from "../../Assets/HomePage/IMG3.jpg";
import IMG4 from "../../Assets/HomePage/IMG4.jpg";
import IMG5 from "../../Assets/HomePage/IMG5.jpg";
import IMG6 from "../../Assets/HomePage/IMG6.jpg";
import IMG7 from "../../Assets/HomePage/IMG7.jpg";
import IMG8 from "../../Assets/HomePage/IMG8.jpg";
import IMG9 from "../../Assets/HomePage/IMG9.jpg";
import IMG10 from "../../Assets/HomePage/IMG10.jpg";
import IMG11 from "../../Assets/HomePage/IMG11.jpg";
import IMG12 from "../../Assets/HomePage/IMG12.jpg";
import IMG13 from "../../Assets/HomePage/IMG13.jpg";
import IMG14 from "../../Assets/HomePage/IMG14.jpg";
import IMG15 from "../../Assets/HomePage/IMG15.jpg";
import IMG16 from "../../Assets/HomePage/IMG16.jpg";
//import IMG17 from "../../Assets/HomePage/IMG17.jpg";
import IMG18 from "../../Assets/HomePage/IMG18.jpg";
import IMG19 from "../../Assets/HomePage/IMG19.jpg";
import IMG20 from "../../Assets/HomePage/IMG20.jpg";
import IMG21 from "../../Assets/HomePage/IMG21.jpg";

import IMG23 from "../../Assets/HomePage/IMG23.jpg";
import IMG24 from "../../Assets/HomePage/IMG24.jpg";
import IMG25 from "../../Assets/HomePage/IMG25.jpg";
import IMG26 from "../../Assets/HomePage/IMG26.jpg";
import BlackBg from "Components/BlackBg/BlackBg";

function Home() {
  const style: React.CSSProperties = {
    backgroundPosition: "center center",
    position: "absolute",
    top: 0,
    zIndex: -1,
  };
  return (
    <BlackBg>
      <Header type="" />
      <MenuWrapper>
        <Contacts />
        <Menu />
      </MenuWrapper>

      <SimpleImageSlider
        width={"100vw"}
        height={"100vh"}
        images={[
          IMG1,
          IMG2,
          IMG3,
          IMG4,
          IMG5,
          IMG6,
          IMG7,
          IMG8,
          IMG9,
          IMG10,
          IMG11,
          IMG12,
          IMG13,
          IMG14,
          IMG15,
          IMG16,
          IMG18,
          IMG19,
          IMG20,
          IMG21,
          // IMG22,
          IMG23,
          IMG24,
          IMG25,
          IMG26,
        ]}
        style={style}
        showNavs={false}
        showBullets={false}
        slideDuration={1}
        autoPlayDelay={5}
        autoPlay={true}
      />
      <Footer />
    </BlackBg>
  );
}

export default Home;
