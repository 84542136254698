import React from "react";

import Footer from "../../../Footer";
import Header from "../../../Headers/Header";
import SimpleImageSlider from "react-simple-image-slider";

import IMG0 from "../../../../Assets/Film/Born/Zrozena0.jpg";
import IMG1 from "../../../../Assets/Film/Born/Zrozena1.jpg";
import IMG2 from "../../../../Assets/Film/Born/Zrozena2.jpg";
import IMG3 from "../../../../Assets/Film/Born/Zrozena3.jpg";
import IMG4 from "../../../../Assets/Film/Born/Zrozena4.jpg";

import IMG5 from "../../../../Assets/Film/Born/Zrozena5.jpg";
import IMG6 from "../../../../Assets/Film/Born/Zrozena6.jpg";
import IMG7 from "../../../../Assets/Film/Born/Zrozena7.jpg";
import IMG8 from "../../../../Assets/Film/Born/Zrozena8.jpg";
import IMG9 from "../../../../Assets/Film/Born/Zrozena9.jpg";

import Iframe from "react-iframe";
import BlackBg from "Components/BlackBg/BlackBg";

const Born = () => {
  const style: React.CSSProperties = {
    backgroundPosition: "center center",
  };

  const styleF: React.CSSProperties = {
    height: "100vh",
    width: "100vw",
    zIndex: "2",
    backgroundColor: "black",
    /*  paddingBottom: "20px", */
  };
  return (
    <BlackBg>
      <Header
        type="film"
        name="i was born from burning apples"
        dir="Karolína Brabcová"
        redir="film"
      />

      <SimpleImageSlider
        width={"100vw"}
        height={"100vh"}
        images={[IMG9, IMG2, IMG1, IMG3, IMG5, IMG4, IMG6, IMG7, IMG8, IMG0]}
        style={style}
        showNavs={true}
        showBullets={false}
        slideDuration={1}
        autoPlayDelay={5}
        autoPlay={true}
      />
      <Iframe
        url="https://player.vimeo.com/video/803530356?h=594952690d"
        display="block"
        styles={styleF}
        position="relative"
        frameBorder={0}
        allowFullScreen={false}
      />
      <Footer />
    </BlackBg>
  );
};

export default Born;
