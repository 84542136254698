import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  HeaderStyled,
  HeaderWrapper,
  NameWrapper,
  Nav,
  NavWrapper,
  ProjectWrapper,
} from "../Styled/Header.styled";

export interface HeaderProps {
  type?: string;
  name?: string;
  dir?: string;
  redir?: string;
}

const Header = ({ type, name, dir, redir }: HeaderProps) => {
  return (
    <HeaderStyled>
      <Link to="/">
        <NameWrapper>
          <div>
            <h1>ondřej andrešič</h1>
          </div>
          <div>
            <p>cinematographer</p>
          </div>
        </NameWrapper>
      </Link>
      <NavWrapper>
        <Link to={`/${redir}`}>
          {type ? (
            <Nav>
              <h1>{type}</h1>
              {name ? (
                <ProjectWrapper>
                  <h5>{name}</h5> <h6>{dir}</h6>
                </ProjectWrapper>
              ) : (
                <></>
              )}
            </Nav>
          ) : (
            <></>
          )}
        </Link>
      </NavWrapper>
    </HeaderStyled>
  );
};

export default Header;
