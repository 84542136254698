import styled from "styled-components";

export const StyledFooter = styled.footer`
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  text-align: center;

  font-size: 1.875rem;
  background-color: transparent;
  z-index: 1; /*  padding: 1.25rem 0 0 0; */
  a {
    color: #a1a6b4;
  }

  @media screen and (max-width: 960px) {
    /*   background-size: 200%; */
  }

  @media screen and (max-width: 768px) {
    /*   display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    padding-bottom: 10px; */
  }

  @media screen and (max-width: 900px) {
    font-size: 1rem;
  }
`;
