import React from "react";
import { Link } from "react-router-dom";
import { Container, Text } from "../Styled/FilmContainer.styled";
import { Image } from "../Styled/FilmContainer.styled";

export interface ProjectProps {
  dir: string;
  name: string;
  IMG?: string;
  LinkTo: string;
  position: string;
  withBackground?: boolean;
}

const Project = ({ dir, name, IMG, LinkTo, position }: ProjectProps) => {
  return (
    <Link to={LinkTo}>
      <Container>
        <Image src={IMG} alt="" />
        <Text as="div" position={position}>
          <h1>{name}</h1>
          {dir ? <h1>dir. {dir}</h1> : <></>}
        </Text>
      </Container>
    </Link>
  );
};

export default Project;
