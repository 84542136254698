import Footer from "Components/Footer";
import Header from "Components/Headers/Header";
import React, { useLayoutEffect, useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";

import IMG1 from "../../../../Assets/Commercial/PlodyWork/PlodyWork8.jpg";
import IMG2 from "../../../../Assets/Commercial/PlodyWork/PlodyWork5.jpg";
import IMG3 from "../../../../Assets/Commercial/PlodyWork/PlodyWork10.jpg";
import IMG4 from "../../../../Assets/Commercial/PlodyWork/PlodyWork9.jpg";
import IMG5 from "../../../../Assets/Commercial/PlodyWork/PlodyWork2.jpg";
import { Wrapper } from "../style";
import "../../../../App.css";
import Iframe from "react-iframe";
import BlackBg from "Components/BlackBg/BlackBg";

const PlodyWork = () => {
  const style: React.CSSProperties = {
    backgroundPosition: "center center",
  };
  const styleF: React.CSSProperties = {
    height: "100vh",
    width: "100vw",
    zIndex: "2",
    backgroundColor: "black",
    /*  paddingBottom: "20px", */
  };
  return (
    <BlackBg>
      <Header
        type="commercial"
        name="plody.work"
        dir="Valeria Recman"
        redir="commercial"
      />
      <Wrapper>
        <SimpleImageSlider
          width={"100vw"}
          height={"74.352vh"}
          images={[IMG1, IMG2, IMG3, IMG4, IMG5]}
          style={style}
          showNavs={true}
          showBullets={false}
          slideDuration={1}
          autoPlayDelay={5}
          autoPlay={true}
        />
      </Wrapper>
      <Iframe
        url="https://player.vimeo.com/video/803066570?h=0b972e4621"
        width="100%"
        display="block"
        styles={styleF}
        position="relative"
        frameBorder={0}
        allowFullScreen={false}
      />
      <Footer />
    </BlackBg>
  );
};

export default PlodyWork;
