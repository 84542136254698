import React from "react";
import { ContactsContainer } from "./Styled/Contacts.styled";
const Contacts = () => {
  return (
    <ContactsContainer>
      <h5>ondrej.andresic@gmail.com</h5>
      <h5>+420776866601</h5>
    </ContactsContainer>
  );
};

export default Contacts;
