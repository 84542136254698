import React from "react";
import BlackBg from "../../../BlackBg/BlackBg";
import Header from "../../../Headers/Header";
import SimpleImageSlider from "react-simple-image-slider";
import IMG1 from "../../../../Assets/MusicVideo/StaleSom/StaleSom1.jpg";
import IMG2 from "../../../../Assets/MusicVideo/StaleSom/StaleSom2.jpg";
import IMG3 from "../../../../Assets/MusicVideo/StaleSom/StaleSom3.jpg";
import IMG4 from "../../../../Assets/MusicVideo/StaleSom/StaleSom4.jpg";
import IMG5 from "../../../../Assets/MusicVideo/StaleSom/StaleSom5.jpg";
import IMG6 from "../../../../Assets/MusicVideo/StaleSom/StaleSom6.jpg";
import IMG7 from "../../../../Assets/MusicVideo/StaleSom/StaleSom7.jpg";
import IMG8 from "../../../../Assets/MusicVideo/StaleSom/StaleSom8.jpg";
import IMG9 from "../../../../Assets/MusicVideo/StaleSom/StaleSom9.jpg";
import IMG10 from "../../../../Assets/MusicVideo/StaleSom/StaleSom10.jpg";
import IMG11 from "../../../../Assets/MusicVideo/StaleSom/StaleSom11.jpg";
import IMG12 from "../../../../Assets/MusicVideo/StaleSom/StaleSom12.jpg";
import IMG13 from "../../../../Assets/MusicVideo/StaleSom/StaleSom13.jpg";
import IMG14 from "../../../../Assets/MusicVideo/StaleSom/StaleSom14.jpg";
import IMG15 from "../../../../Assets/MusicVideo/StaleSom/StaleSom15.jpg";
import IMG16 from "../../../../Assets/MusicVideo/StaleSom/StaleSom16.jpg";
import IMG17 from "../../../../Assets/MusicVideo/StaleSom/StaleSom17.jpg";
import IMG18 from "../../../../Assets/MusicVideo/StaleSom/StaleSom18.jpg";
import IMG19 from "../../../../Assets/MusicVideo/StaleSom/StaleSom19.jpg";
import IMG20 from "../../../../Assets/MusicVideo/StaleSom/StaleSom20.jpg";
import IMG21 from "../../../../Assets/MusicVideo/StaleSom/StaleSom21.jpg";
import IMG22 from "../../../../Assets/MusicVideo/StaleSom/StaleSom22.jpg";
import IMG23 from "../../../../Assets/MusicVideo/StaleSom/StaleSom23.jpg";
import IMG24 from "../../../../Assets/MusicVideo/StaleSom/StaleSom24.jpg";
import IMG25 from "../../../../Assets/MusicVideo/StaleSom/StaleSom25.jpg";

import Iframe from "react-iframe";
import Footer from "../../../Footer";

const StaleSom = () => {
  const style: React.CSSProperties = {
    backgroundPosition: "center center",
  };
  const styleF: React.CSSProperties = {
    height: "100vh",
    width: "100vw",
    zIndex: "2",
    backgroundColor: "black",
    /*  paddingBottom: "20px", */
  };
  return (
    <BlackBg>
      <Header
        type="music video"
        name="stále som (16mm)"
        dir="Tomáš Fiala"
        redir="music-video"
      />
      <SimpleImageSlider
        width={"100vw"}
        height={"100vh"}
        images={[
          IMG1,
          IMG2,
          IMG3,
          IMG4,
          IMG5,
          IMG6,
          IMG7,
          IMG8,
          IMG9,
          IMG10,
          IMG11,
          IMG12,
          IMG13,
          IMG14,
          IMG15,
          IMG16,
          IMG17,
          IMG18,
          IMG19,
          IMG20,
          IMG21,
          IMG22,
          IMG23,
          IMG24,
          IMG25,
        ]}
        style={style}
        showNavs={true}
        showBullets={false}
        slideDuration={1}
        autoPlayDelay={5}
        autoPlay={true}
      />
      <Iframe
        url="https://player.vimeo.com/video/980690443?h=0dab672c3d"
        width="100%"
        display="block"
        styles={styleF}
        position="relative"
        frameBorder={0}
        allowFullScreen={false}
      />
      <Footer />
    </BlackBg>
  );
};

export default StaleSom;
