import React from "react";

import Footer from "../../../Footer";
import Header from "../../../Headers/Header";
import SimpleImageSlider from "react-simple-image-slider";

import IMGX from "../../../../Assets/Film/Amori/AmoriX.jpg";
import IMG1 from "../../../../Assets/Film/Amori/Amori41.jpg";
import IMG2 from "../../../../Assets/Film/Amori/Amori43.jpg";
import BTS2 from "../../../../Assets/Film/Amori/BTS/AmoriBTS2.jpg";
import IMG3 from "../../../../Assets/Film/Amori/Amori39.jpg";
import BTS1 from "../../../../Assets/Film/Amori/BTS/AmoriBTS1.jpg";
import IMG4 from "../../../../Assets/Film/Amori/Amori44.jpg";
import IMG5 from "../../../../Assets/Film/Amori/Amori38.jpg";
import IMG6 from "../../../../Assets/Film/Amori/Amori40.jpg";
import BTS3 from "../../../../Assets/Film/Amori/BTS/AmoriBTS3.jpg";
import IMG7 from "../../../../Assets/Film/Amori/Amori37.jpg";
import IMG8 from "../../../../Assets/Film/Amori/Amori28.jpg";
import IMG9 from "../../../../Assets/Film/Amori/Amori25.jpg";
import IMG10 from "../../../../Assets/Film/Amori/Amori7.jpg";
import IMG11 from "../../../../Assets/Film/Amori/Amori30.jpg";
import IMG12 from "../../../../Assets/Film/Amori/Amori22.jpg";
import IMG13 from "../../../../Assets/Film/Amori/Amori24.jpg";
import IMG14 from "../../../../Assets/Film/Amori/Amori10.jpg";
import IMG15 from "../../../../Assets/Film/Amori/Amori19.jpg";
import IMG16 from "../../../../Assets/Film/Amori/Amori12.jpg";
import BTS4 from "../../../../Assets/Film/Amori/BTS/AmoriBTS4.jpg";

import Iframe from "react-iframe";
import BlackBg from "Components/BlackBg/BlackBg";

const Amori = () => {
  const style: React.CSSProperties = {
    backgroundPosition: "center center",
  };

  const styleF: React.CSSProperties = {
    height: "100vh",
    width: "100vw",
    zIndex: "2",
    backgroundColor: "black",
    /*  paddingBottom: "20px", */
  };
  return (
    <BlackBg>
      <Header
        type="film"
        name="amori dolori"
        dir="Valeria Recman"
        redir="film"
      />

      <SimpleImageSlider
        width={"100vw"}
        height={"100vh"}
        images={[
          IMGX,
          IMG1,
          IMG2,
          BTS2,
          IMG3,
          BTS1,
          IMG4,
          IMG5,
          IMG6,
          BTS3,
          IMG7,
          IMG8,
          IMG9,
          IMG10,
          IMG11,
          IMG12,
          IMG13,
          IMG14,
          IMG15,
          IMG16,
          BTS4,
        ]}
        style={style}
        showNavs={true}
        showBullets={false}
        slideDuration={1}
        autoPlayDelay={5}
        autoPlay={true}
      />
      <Iframe
        url="https://player.vimeo.com/video/751644405?h=41f1df4007&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        width="100%"
        display="block"
        styles={styleF}
        position="relative"
        frameBorder={0}
        allowFullScreen={false}
      />
      <Footer />
    </BlackBg>
  );
};

export default Amori;
