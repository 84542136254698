import Footer from "Components/Footer";
import Header from "Components/Headers/Header";
import React from "react";
import SimpleImageSlider from "react-simple-image-slider";

import IMG1 from "../../../../Assets/Commercial/StudyIn/StudyIn26.jpg";
import IMG2 from "../../../../Assets/Commercial/StudyIn/StudyIn5.jpg";
import IMG3 from "../../../../Assets/Commercial/StudyIn/StudyIn24.jpg";
import IMG4 from "../../../../Assets/Commercial/StudyIn/StudyIn25.jpg";
import IMG5 from "../../../../Assets/Commercial/StudyIn/StudyIn22.jpg";
import IMG6 from "../../../../Assets/Commercial/StudyIn/StudyIn29.jpg";
import IMG7 from "../../../../Assets/Commercial/StudyIn/StudyIn7.jpg";
import IMG8 from "../../../../Assets/Commercial/StudyIn/StudyIn11.jpg";
import IMG9 from "../../../../Assets/Commercial/StudyIn/StudyIn13.jpg";
import IMG10 from "../../../../Assets/Commercial/StudyIn/StudyIn16.jpg";
import IMG11 from "../../../../Assets/Commercial/StudyIn/StudyIn17.jpg";
import IMG12 from "../../../../Assets/Commercial/StudyIn/StudyIn20.jpg";
import IMG13 from "../../../../Assets/Commercial/StudyIn/StudyIn23.jpg";
import IMG14 from "../../../../Assets/Commercial/StudyIn/StudyIn27.jpg";
import IMG15 from "../../../../Assets/Commercial/StudyIn/StudyIn28.jpg";
import IMG16 from "../../../../Assets/Commercial/StudyIn/StudyIn30.jpg";
import IMG17 from "../../../../Assets/Commercial/StudyIn/StudyIn14.jpg";

import Iframe from "react-iframe";
import BlackBg from "Components/BlackBg/BlackBg";

const StudyIn = () => {
  const style: React.CSSProperties = {
    backgroundPosition: "center center",
  };
  const styleF: React.CSSProperties = {
    height: "100vh",
    width: "100vw",
    zIndex: "2",
    backgroundColor: "black",
    /*  paddingBottom: "20px", */
  };
  return (
    <BlackBg>
      <Header
        type="commercial"
        name="study in"
        dir="Bartłomiej Klimsza"
        redir="commercial"
      />
      <SimpleImageSlider
        width={"100vw"}
        height={"100vh"}
        images={[
          IMG3,
          IMG4,
          IMG15,
          IMG14,
          IMG13,
          IMG11,
          IMG5,
          IMG12,
          IMG6,
          IMG7,
          IMG10,
          IMG8,
          IMG9,
          IMG16,
          IMG17,
        ]}
        style={style}
        showNavs={true}
        showBullets={false}
        slideDuration={1}
        autoPlayDelay={5}
        autoPlay={true}
      />{" "}
      <Iframe
        url="https://player.vimeo.com/video/751669090?h=4664764a5e"
        width="100%"
        display="block"
        styles={styleF}
        position="relative"
        frameBorder={0}
        allowFullScreen={false}
      />
      <Footer />
    </BlackBg>
  );
};

export default StudyIn;
