import React from "react";

import Footer from "../Footer";

import { Container } from "../Styled/FilmContainer.styled";

import Header from "../Headers/Header";
import Project from "../Project/Project";
import "../../App.css";
import AmoriImg from "../../Assets/Film/Amori/Amori1.jpg";
import bornImg from "../../Assets/Film/Born/Zrozena4.jpg";
import sandeepImg from "../../Assets/Film/sandeep/Sandeep0.jpg";
import BlackBg from "Components/BlackBg/BlackBg";

function Film() {
  return (
    <BlackBg>
      <Header type="film" redir="" />
      <Container>
        {/*Item 1*/}
        <Project
          position="bottom"
          IMG={AmoriImg}
          LinkTo={"amori-dolori"}
          dir={"Valeria Recman"}
          name={"amori dolori"}
        />
        <Project
          position="bottom"
          IMG={sandeepImg}
          LinkTo={"sandeep"}
          dir={"Paolo Gentilella"}
          name={"sandeep!"}
        />
        <Project
          LinkTo="i-was-born-from-burning-apples"
          dir={"Karolína Brabcová"}
          name={"i was born from burning apples"}
          position={"bottom"}
          IMG={bornImg}
        />
      </Container>
      <Footer />
    </BlackBg>
  );
}

export default Film;
