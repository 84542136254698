import React from "react";
import BlackBg from "../../../BlackBg/BlackBg";
import Header from "../../../Headers/Header";
import SimpleImageSlider from "react-simple-image-slider";
import IMG1 from "../../../../Assets/MusicVideo/WhatUWant/What1.jpg";
import IMG2 from "../../../../Assets/MusicVideo/WhatUWant/What2.jpg";
import IMG3 from "../../../../Assets/MusicVideo/WhatUWant/What3.jpg";
import IMG4 from "../../../../Assets/MusicVideo/WhatUWant/What4.jpg";
import IMG5 from "../../../../Assets/MusicVideo/WhatUWant/What5.jpg";
import IMG6 from "../../../../Assets/MusicVideo/WhatUWant/What6.jpg";
import IMG7 from "../../../../Assets/MusicVideo/WhatUWant/What7.jpg";
import IMG8 from "../../../../Assets/MusicVideo/WhatUWant/What8.jpg";
import IMG9 from "../../../../Assets/MusicVideo/WhatUWant/What9.jpg";
import IMG10 from "../../../../Assets/MusicVideo/WhatUWant/What10.jpg";
import IMG11 from "../../../../Assets/MusicVideo/WhatUWant/What11.jpg";
import IMG12 from "../../../../Assets/MusicVideo/WhatUWant/What12.jpg";
import IMG13 from "../../../../Assets/MusicVideo/WhatUWant/What13.jpg";
import IMG14 from "../../../../Assets/MusicVideo/WhatUWant/What14.jpg";
import IMG15 from "../../../../Assets/MusicVideo/WhatUWant/What15.jpg";
import IMG16 from "../../../../Assets/MusicVideo/WhatUWant/What16.jpg";
import IMG17 from "../../../../Assets/MusicVideo/WhatUWant/What17.jpg";
import IMG18 from "../../../../Assets/MusicVideo/WhatUWant/What18.jpg";
import IMG19 from "../../../../Assets/MusicVideo/WhatUWant/What19.jpg";
import IMG20 from "../../../../Assets/MusicVideo/WhatUWant/What20.jpg";
import IMG21 from "../../../../Assets/MusicVideo/WhatUWant/What21.jpg";

import Iframe from "react-iframe";
import Footer from "../../../Footer";

const WhatYouWant = () => {
  const style: React.CSSProperties = {
    backgroundPosition: "center center",
  };
  const styleF: React.CSSProperties = {
    height: "100vh",
    width: "100vw",
    zIndex: "2",
    backgroundColor: "black",
    /*  paddingBottom: "20px", */
  };
  return (
    <BlackBg>
      <Header
        type="music video"
        name="what u want?"
        dir="Tomáš Fiala"
        redir="music-video"
      />
      <SimpleImageSlider
        width={"100vw"}
        height={"100vh"}
        images={[
          IMG1,
          IMG2,
          IMG3,
          IMG4,
          IMG5,
          IMG6,
          IMG7,
          IMG8,
          IMG9,
          IMG10,
          IMG11,
          IMG12,
          IMG13,
          IMG14,
          IMG15,
          IMG16,
          IMG17,
          IMG18,
          IMG19,
          IMG20,
          IMG21,
        ]}
        style={style}
        showNavs={true}
        showBullets={false}
        slideDuration={1}
        autoPlayDelay={5}
        autoPlay={true}
      />
      <Iframe
        url="https://player.vimeo.com/video/936929849?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        width="100%"
        display="block"
        styles={styleF}
        position="relative"
        frameBorder={0}
        allowFullScreen={false}
      />
      <Footer />
    </BlackBg>
  );
};

export default WhatYouWant;
